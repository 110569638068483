import React from "react"
import Layout from "./layout"
import ScrollySection from "./explainer/ScrollySection"
import { motion } from "framer-motion"
import { currentLanguage, Language } from "./i18n"

export default () => (
  <Layout
    menuHidden={currentLanguage === Language.ChineseTaiwan ? true : false}
    alternateMenu={currentLanguage === Language.ChineseTaiwan ? true : false}
  >
    <motion.main animate="undefined" exit="undefined">
      <ScrollySection />
    </motion.main>
  </Layout>
)
