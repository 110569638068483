import React from "react"
import { Helmet } from "react-helmet"
import { t, currentLanguage, Language } from "../i18n"
import Explainer from "../explainer"

export default () => (
  <>
    <Helmet>
      <meta property="og:url" content="https://moondisaster.org/explainer" />
      <meta property="og:title" content={t("explainer.meta.title")} />
      <meta
        property="og:description"
        content={t("explainer.meta.description")}
      />
      <meta
        property="og:image:secure_url"
        content="https://moondisaster.space/wp-content/uploads/2020/02/AI_mapping-1200x630-cropped.jpg"
      />
      <meta
        property="og:image:url"
        content="https://moondisaster.space/wp-content/uploads/2020/02/AI_mapping-1200x630-cropped.jpg"
      />
      <meta
        property="og:image"
        content="https://moondisaster.space/wp-content/uploads/2020/02/AI_mapping-1200x630-cropped.jpg"
      />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
      <meta property="og:image:alt" content={t("explainer.meta.imageAlt")} />
      <meta
        property="article:author:name"
        content={t("explainer.meta.authorName")}
      />
      <meta name="twitter:title" content={t("explainer.meta.title")} />
      <meta
        name="twitter:description"
        content={t("explainer.meta.description")}
      />
      <meta name="twitter:creator" content={t("explainer.meta.authorName")} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta
        name="twitter:image"
        content="https://moondisaster.space/wp-content/uploads/2020/02/AI_mapping-1200x446.jpg?p=1465"
      />
      <meta name="twitter:image:alt" content={t("explainer.meta.imageAlt")} />
      <meta itemProp="name" content={t("explainer.meta.title")} />
      <meta itemProp="description" content={t("explainer.meta.description")} />
      <meta name="author" content={t("explainer.meta.authorName")} />
      <meta name="description" content={t("explainer.meta.description")} />
      <meta
        name="thumbnail"
        content="https://moondisaster.space/wp-content/uploads/2020/02/AI_mapping-1200x630-cropped.jpg"
      />
    </Helmet>
    <Explainer />
  </>
)
